<template>
  <page-main
    title="Your Accolades"
    :aside="[
      'Pick one or two of the awards, acheivements, projects or honors you entered. Think about which ones best highlight your skills and accomplishments.',
      'Which ones have the most relevance for the type of position you are after with this resume?',
      'Use the tool to structure your accolades. Adjust the phrase manually, if the suggested text doesn\'t flow well.',
    ]"
    nextText="Finish this Education entry"
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="confirmNav()"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-expansion-panels v-model="panel">
            <r-expansion-panel v-for="(item, i) in accolades" :key="i">
              <r-expansion-panel-header>
                <div class="d-flex align-center">
                  <div class="pr-1">
                    <s-checkbox color="white" v-model="item.selected">
                    </s-checkbox>
                  </div>
                  <div class="pr-4">{{ item.text }}</div>
                  <div :class="item.selected ? '' : 'text--secondary--dark'">
                    {{ item.useCustom ? item.displayCustom : item.displayAuto }}
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row class="pt-4">
                    <v-col cols="12" class="r-col">
                      <p class="text-body-1 font-italic text--secondary">
                        Fill in the following prompts to get a suggested phrase.
                        If needed, adjust the phrase yourself to correct
                        phrasing issues.
                      </p>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-text-field
                        v-model="item.role"
                        label="What was your position/title/role/honor?"
                      ></r-text-field>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-text-field
                        v-model="item.how"
                        label="How did you attain the position/title/role/honor? (One word - Elected, Selected, Nominated, Awarded, Volunteered)"
                      ></r-text-field>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-text-field
                        v-model="item.orgName"
                        label="What is the sponsoring organization's name (if applicable)"
                      ></r-text-field>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-text-field
                        v-model="item.achievementLarge"
                        label="What did you acheive?"
                      ></r-text-field>
                    </v-col>
                    <v-col cols="12" class="pl-5">
                      <h3>Suggested Phrase:</h3>
                      <p class="text-body-1">{{ item.displayAuto }}</p>
                      <v-switch
                        v-model="item.useCustom"
                        label="Need to make an adjustment?"
                      ></v-switch>
                      <transition name="line-66">
                        <v-textarea
                          v-if="item.useCustom"
                          v-model="item.displayCustom"
                          label="Adjust the phrase"
                          auto-grow
                          rows="3"
                        ></v-textarea>
                      </transition>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="readyDialog" :title="dialogTitle">
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <div v-if="selectedAccoladeCount == 0">
          <p class="text-body-1 text--primary mb-0">
            Are you sure you don't want to include any Accolades?
          </p>
          <span class="text-body-2 text--secondary font-italic">
            Use the check box next to an Accolade to include it.
          </span>
        </div>
        <div v-else>
          <p class="text-body-1 text--primary mb-0">
            You have selected more than two Accolades. Are you sure you want to
            include all of them?
          </p>
          <p class="text-body-2 text--secondary font-italic">
            Use the check box next to an Accolade to include it.
          </p>
          <p v-for="acc in selectedAccolades" :key="acc.id" class="text-body-2">
            {{ getDisplay(acc) }}
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="readyDialog = false"> Cancel </v-btn>
        <v-btn color="primary" outlined @click="nav('/Education')">
          Finished with Entry
        </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as disp from "../utility/display";
import ShortCheckBox from "../components/ShortCheckbox.vue";

// @ is an alias to /src
export default {
  name: "EducationEntryAwardFocus",
  components: {
    "s-checkbox": ShortCheckBox,
  },
  data: () => ({
    accolades: [],
    pageKey: "",
    panel: 0,
    interval: null,
    readyDialog: false,
  }),
  watch: {
    accolades: {
      deep: true,
      handler() {
        this.accolades.forEach((acc) => {
          let setCustomToAuto =
            disp.IsNullorWhitespace(acc.displayCustom) ||
            acc.displayCustom === acc.displayAuto;

          if (!disp.IsNullorWhitespace(acc.achievementLarge)) {
            acc.displayAuto = `${acc.how} ${acc.role} of ${acc.orgName}. ${acc.achievementLarge}.`;
          } else if (
            !disp.IsNullorWhitespace(acc.role) &&
            !disp.IsNullorWhitespace(acc.orgName)
          ) {
            acc.displayAuto = `${
              disp.IsNullorWhitespace(acc.how) ? "" : `${acc.how} `
            } ${acc.role} of ${acc.orgName}.`;
          } else {
            acc.displayAuto = "";
          }

          if (setCustomToAuto) {
            acc.displayCustom = acc.displayAuto;
          }
        });
      },
    },
  },
  mounted() {
    this.pageKey = this.$route.params.key;
    if (this.key != this.pageKey) {
      if (!this.doesKeyExist(this.pageKey)) {
        this.createEntry({ key: this.pageKey });
      }
      this.key = this.pageKey;
    }

    this.buildAccolades();
    this.accolades = this.accoladesStore;

    // set up auto-save 60s interval
    this.interval = setInterval(() => this.saveEducation(), 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    save() {
      this.accoladesStore = this.accolades;
      this.saveEducation();
    },
    confirmNav() {
      if (this.selectedAccoladeCount === 0 || this.selectedAccoladeCount > 2) {
        this.readyDialog = true;
      } else {
        this.nav("/Education");
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    getDisplay(accolade) {
      if (accolade.useCustom) {
        return accolade.displayCustom;
      } else {
        return accolade.displayAuto;
      }
    },
    ...mapMutations("education", ["createEntry", "buildAccolades"]),
    ...mapActions("education", ["saveEducation"]),
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist"]),
    ...mapFields("education", {
      key: "key",
      accoladesStore: "educationAccolades",
    }),
    nextti() {
      return "5"; // todo: Fix me
    },
    selectedAccolades() {
      return this.accolades.filter((a) => a.selected == true);
    },
    selectedAccoladeCount() {
      return this.selectedAccolades.length;
    },
    dialogTitle() {
      return `You have selected ${this.selectedAccoladeCount} Accolades`;
    },
  },
};
</script>
